import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Form, Button } from "../../../../../template";
import loader from "../../../../../innedit_src/assets/images/loading.svg";
import actionObject from "../../../../../innedit_src/datas/Action";
import { USER_UPDATE_FIELDS } from "../../../../../innedit_src/plugins/user/actionTypes";

const Popup = styled.div`
  z-index: 2000;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .popup {
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: ${props => (props.theme.mobile ? "100%" : "340px")};
    height: ${props => (props.theme.mobile ? "auto" : "506px")};
    left: ${props => (props.theme.mobile ? 0 : "calc(50vw - 170px)")};
    top: ${props => (props.theme.mobile ? 0 : "calc(50vh - 253px)")};
    ${props => {
      if (props.theme.mobile) {
        return {
          bottom: 0
        };
      }
    }};
    //background: #cac0b6;
    background: #fff;
    border-radius: ${props => (props.theme.mobile ? 0 : "5px")};
    box-shadow: rgba(0, 0, 0, 0.5) 0 12px 30px 0,
      rgba(255, 255, 255, 0.65) 0 1px 0 0 inset;
  }

  .popup-close {
    &:before {
      content: "✕";
      line-height: 21px;
      display: block;
      width: 19px;
      text-align: center;
    }

    .label {
      display: none;
    }

    position: absolute;

    cursor: pointer;
    top: 14px;
    right: 14px;
    color: #000;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    box-sizing: border-box;
    font-size: 12px;

    padding: 0;
    text-decoration: none;
    border-image: initial;
  }

  .popup-header {
    background: #b08a35;
    height: 48px;
    border-top-left-radius: ${props => (props.theme.mobile ? 0 : "5px")};
    border-top-right-radius: ${props => (props.theme.mobile ? 0 : "5px")};

    h3 {
      padding: 0;
      margin: 0;
      text-align: center;
      line-height: 48px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 70%;
      margin-left: 15%;
      font-size: 20px;
    }
  }

  .popup-content {
    flex: 1;
    padding-top: 1.5rem;
    text-align: center;

    ${props => {
      if (props.theme.mobile) {
        return {
          overflowY: "scroll"
        };
      }
    }};

    .field {
      margin: ${props => (props.theme.mobile ? "0 1.5rem" : 0)};
      input,
      textarea {
        font-size: 16px;
      }
    }

    button {
      width: auto;
      background: #000;
      border-radius: 0;
      color: #fff;
    }
  }
`;

const Pending = () => (
  <div style={{ margin: "0 1.5rem" }}>
    <h3 style={{ textAlign: "center" }}>En cours de traitement</h3>
    <p>
      Votre demande est en cours de traitement, veuillez patienter quelques
      instants.
    </p>
    <img
      style={{ flex: 1, alignSelf: "center" }}
      src={loader}
      alt="En cours de chargement"
    />
  </div>
);

class Demande extends Component {
  state = {
    pending: false,
    success: false,
    newsletter: false
  };

  constructor(props) {
    super(props);

    this.divRef = React.createRef();

    this.handleSubmitDemande = this.handleSubmitDemande.bind(this);
    this.close = this.close.bind(this);
    this.success = this.success.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.documentElement.classList.toggle("is-clipped");
    this.divRef.current.style.top = window.scrollY + "px";
  }

  componentWillUnmount() {
    document.documentElement.classList.remove("is-clipped");
  }

  handleSubmitDemande(data) {
    const datas = {
      produit_id: this.props.produit.objectID
    };

    Object.keys(data).forEach(key => {
      datas[key] = data[key];
    });

    this.props.dispatch({
      type: USER_UPDATE_FIELDS,
      data: datas
    });
    return actionObject.create(datas, "demande-create");
  }

  close(isSuccessed) {
    this.props.handleOnClose(isSuccessed);
  }

  success({ email }) {
    return (
      <React.Fragment>
        <div style={{ flex: 1, margin: "0 1.5rem" }}>
          <h3 className="demande-title">Demande validée et enregistée</h3>
          <p>
            Votre demande a bien été prise en compte et vous recevrez une
            réponse dans les plus bref délais à l'adresse suivante :
          </p>
          <p className="highlight">{email}</p>
          <div className="demande-separator" />
        </div>
        <Button
          style={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}
          onClick={() => this.close(true)}
        >
          Fermer
        </Button>
      </React.Fragment>
    );
  }

  render() {
    const { produit, handleOnClose } = this.props;
    return (
      <Popup ref={this.divRef}>
        <div className="popup">
          <div className="popup-header">
            <button
              className="popup-close"
              onClick={e => {
                e.preventDefault();
                handleOnClose(false);
              }}
            >
              <span className="label">Fermer</span>
            </button>

            <h3>{produit.name}</h3>
          </div>
          <div className="popup-content">
            <Form
              onSubmit={this.handleSubmitDemande}
              datas={this.props.datas}
              textSubmit="Demander le prix"
              pending={Pending}
              success={this.success}
            />
          </div>

          <div className="popup-footer" />
        </div>
      </Popup>
    );
  }
}

Demande.defaultProps = {
  datas: {
    contact_firstname: {
      value: "",
      type: "text",
      required: true,
      hideRequired: true,
      placeholder: "Prénom",
      className: "group first full-width"
    },
    contact_lastname: {
      value: "",
      type: "text",
      required: true,
      hideRequired: true,
      placeholder: "Nom",
      className: "group full-width"
    },
    contact_email: {
      required: true,
      type: "email",
      value: "",
      hideRequired: true,
      placeholder: "Adresse e-mail",
      className: "group full-width"
    },
    contact_phone: {
      type: "tel",
      value: "",
      placeholder: "Téléphone",
      className: "group full-width"
    },
    contact_city: {
      required: true,
      type: "text",
      value: "",
      placeholder: "Ville",
      className: "group full-width"
    },
    content: {
      component: "textarea",
      value: "",
      placeholder: "Message (facultatif)",
      className: "group last full-width"
    },
    contact_newsletter: {
      type: "checkbox",
      value: false,
      description: "S'inscrire à la newsletter",
      className: "group full-width"
    }
  }
};

export default connect()(Demande);
