import React from "react";
import styled from "styled-components";
import Copyright from "./Copyright";
import { Link } from "@reach/router";

const Footer = styled.footer`
  display: flex;
  flex-flow: column;
  font-size: 12px;
  padding: 1.5rem 1.5rem 0.75rem 1.5rem;
  box-sizing: border-box;

  .footer {
    display: flex;
    flex-flow: ${props => (props.theme.mobile ? "column" : "row")};
    justify-content: space-around;

    > div {
      .double,
      .triple {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        ul {
          flex: 1;
          margin-right: 30px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    > div + div {
      margin-top: ${props => (props.theme.mobile ? "1.5rem" : 0)};
    }
    
    .name {
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      a {
        white-space: nowrap;
      }
    }
  }

  h4 {
    font-size: 16px;
    margin: 0 0 1.5rem 0;
    white-space: nowrap;
  }

  p {
    margin: 0 0 0.75rem 0;
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
`;

export default () => (
  <Footer>
    <div className="footer">
      <div>
        <h4>Entreprise</h4>
        <ul>
          <li>
            <Link to="/histoire">Histoire</Link>
          </li>
          <li>
            <Link to="/vendus">Objets vendus</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
      <div>
        <h4>Catégories</h4>
        <div className="double">
          <ul>
            <li>
              <Link to="/inventaire/decoration">Décoration</Link>
            </li>
            <li>
              <Link to="/inventaire/luminaire">Luminaire</Link>
            </li>
            <li>
              <Link to="/inventaire/assise">Assise</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/inventaire/table">Table</Link>
            </li>
            <li>
              <Link to="/inventaire/meuble">Meuble</Link>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <h4>Liens</h4>
        <ul>
          <li>
            <Link to="/transport">Transport</Link>
          </li>
          <li>
            <Link to="/newsletters">Newsletters</Link>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/rodrigue.verhaeghe/"
            >
              Instagram
            </a>
          </li>
        </ul>
      </div>
    </div>

    <Copyright />
  </Footer>
);
