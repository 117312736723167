import React from "react";
import Div from "./style";

export default () => (
  <Div>
    <h3>Modèle d'exposition</h3>
    <p>Pour chaque modèle, le produit d'exposition est mis en vente.</p>
    <p>
      Ce produit est celui qui a été fabriqué pour présenter le modèle sur le
      site. Il est ainsi particulier car c'est le tout premier à être fabriqué.
    </p>
    <p className="autres">
      Il est également possible que le produit en stock soit un autre exemplaire
      <sup>*</sup> de ce modèle (cf. fabrication & stock).
    </p>
  </Div>
);
