import React, { Component } from "react";
import { Content, Form } from "../template";
import actionObject from "../innedit_src/datas/Action";

class Newsletter extends Component {
  constructor(props) {
    super(props);

    this.handleSubmitNewsletter = this.handleSubmitNewsletter.bind(this);
  }

  handleSubmitNewsletter(data) {
    const datas = {};
    Object.keys(data).forEach(key => {
      datas[key] = data[key];
    });

    // TODO Ajouter les champs USER_UPDATE_FIELDS comme avant
    return actionObject.create(datas, "newsletter-signup");
  }

  success({ email }) {
    return (
      <React.Fragment>
        <div style={{ flex: 1 }}>
          <p style={{ textAlign: "center" }}>
            Votre inscription a bien été prise en compte.
          </p>
          <p style={{ textAlign: "center" }}>
            Vous receverez la prochaine newletter à l'adresse suivante :
          </p>
          <p className="highlight" style={{ textAlign: "center" }}>
            {email}
          </p>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Content
        title="Newsletter"
        head={{
          title: "Newsletter - Rodrigue Verhaeghe"
        }}
      >
        <Form
          onSubmit={this.handleSubmitNewsletter}
          datas={this.props.datas}
          className="main"
          textSubmit="Je m'inscris à la newsletter"
          success={this.success}
        >
          <h2>S'inscrire à la newsletter</h2>
          <p>
            Vous pouvez vous inscrire à la newsletter pour recevoir 2 fois par
            mois mes nouveautés et produits spécialement chinés pour vous.
          </p>
          <h2>Pourquoi s'inscrire ?</h2>
          <p>
            Vous receverrez en exclusivité ma dernière selection de produits et
            d'objets de tous genres.
          </p>
        </Form>
      </Content>
    );
  }
}

Newsletter.defaultProps = {
  datas: {
    contact_email: {
      required: true,
      type: "email",
      value: "",
      hideRequired: true,
      placeholder: "Adresse e-mail",
      className: "group full-width"
    },
    contact_newsletter: {
      type: "checkbox",
      value: true,
      hidden: true,
      description: "S'inscrire à la newsletter",
      className: "group full-width"
    }
  }
};

export default Newsletter;
