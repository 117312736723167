import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./translations/en.json";
import fr from "./translations/fr.json";

const resources = { en, fr };

i18n.use(LanguageDetector).init({
  resources,
  fallbackLng: "en",
  keySeparator: ".", // we use keys in form messages.welcome
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;
