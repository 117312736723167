import React, { Component } from "react";
import styled from "styled-components";

const StyledPhotos = styled.div`
  max-width: ${props => (props.theme.mobile ? "100%" : "600px")};
  margin: ${props => (props.theme.mobile ? 0 : "1.5rem 4.5rem 0 1.5rem")};
  flex: 5;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 1.5rem;
    }
  }

  .aspect-ratio--square {
    position: relative;
    display: block;
    //background: #f7f7f7;
    padding: 0;

    &:before {
      z-index: 1;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      // border: 1px solid rgba(195, 207, 216, 0.3);
    }

    width: 100%;
    padding-bottom: 100%;
    flex-shrink: 0;
    overflow: hidden;

    img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }

  .main-picture {
    margin-bottom: 1.5rem;
    img {
      display: block;
    }
  }

  .miniatures {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;

    li {
      cursor: pointer;
      background: #f7f7f7;
      display: inline-block;
      margin: 0;
      width: 12%;
      margin-bottom: 2.5%;
      box-sizing: border-box;

      img {
        display: block;
      }
    }

    li + li {
      margin-left: 2.5%;
    }
  }
`;

class Photos extends Component {
  constructor(props) {
    super(props);

    const photos = this.props.datas;
    this.state = {
      photos: photos,
      picture: photos[0]
    };

    this.changePicture = this.changePicture.bind(this);
  }

  changePicture = event => {
    const index = event.currentTarget.getAttribute("data-index");
    this.setState({
      picture: this.state.photos[index]
    });
  };

  render() {
    return (
      <StyledPhotos className="photos">
        {this.state.photos && this.state.photos.length > 1 && (
          <ul>
            {this.state.photos.map((photo, index) => (
              <li key={index}>
                <div>
                  <img
                    alt={photo}
                    src={`${process.env.REACT_APP_CDN_DOMAIN_NAME}/${photo}?w=986&q=50`}
                  />
                </div>
              </li>
            ))}
          </ul>
        )}
      </StyledPhotos>
    );

    // return (
    //   <StyledPhotos className="photos">
    //     <div className="main-picture">
    //       <img
    //         alt={this.state.picture}
    //         src={`${process.env.REACT_APP_CDN_DOMAIN_NAME}/${
    //           this.state.picture
    //         }?w=986&h=986`}
    //       />
    //     </div>
    //
    //     {this.state.photos && this.state.photos.length > 1 && (
    //       <ul className="miniatures">
    //         {this.state.photos.map((photo, index) => (
    //           <li key={index} onClick={this.changePicture} data-index={index}>
    //             {/*<div className='aspect-ratio--square'>*/}
    //             <div>
    //               <img
    //                 alt={photo}
    //                 src={`${
    //                   process.env.REACT_APP_CDN_DOMAIN_NAME
    //                 }/${photo}?w=94&h=94`}
    //               />
    //             </div>
    //           </li>
    //         ))}
    //       </ul>
    //     )}
    //   </StyledPhotos>
    // );
  }
}

export default Photos;
