import React from "react";
import styled from "styled-components";
import innedit from "../../innedit_src/assets/innedit.svg";

const Div = styled.div`
  font-size: 10px;
  padding: 20px 15px 5px 15px;
  display: flex;
  flex-direction: ${props => (props.theme.mobile ? "column-reverse" : "row")};

  .innedit {
    display: inline-block;
    text-align: center;
    margin-top: ${props => (props.theme.mobile ? "1.5rem" : 0)};
  
    img {
      height: 10px;
    } 
  }
  
  div {
    flex: 1;
    text-align: ${props => (props.theme.mobile ? "center" : "right")}; 
  }
  
  span {
    margin-left: 0.375rem;
    
    ${props => {
    if (props.theme.mobile) {
        return {
            display: "block",
            marginLeft: 0
        };
    }
}}
`;

export default () => (
    <Div>
        <a className="innedit" href="//www.innedit.fr">
            <img src={innedit} alt="Innédit, créateur d'idées" />
        </a>
        <div>
            <span>Copyright © 2019 Rodrigue Verhaeghe.</span>
            <span>Tous droits réservés.</span>
        </div>
    </Div>
);
