import React from "react";
import { Link, Match } from "@reach/router";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const MenuStyled = styled.nav`
  ${props => {
    if (props.theme.mobile) {
      return {
        display: "none",
        overflow: "hidden",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        ul: {
          padding: "3rem 3rem 0 3rem",
          visibility: "hidden",
          overflow: "hidden",
          transition: "visibility 0s linear 1s",
          li: {
            display: "block",
            height: "48px",
            borderBottom: "1px solid #eee",
            fontSize: "16px",
            lineHeight: "48px",

            opacity: 0,
            pointerEvents: "none",
            transform: "scale(1.1) translateX(-24px)",
            transition:
              "opacity 0.35s ease-out, transform 0.35s ease-out, -webkit-transform 0.35s ease-out"
          }
        },
        [`&.is-open`]: {
          display: "block"
        }
      };
    }
  }};
`;
// const defautStyle = component => {
//   return styled(component)`
//
//   `;
// };

const ChildrenItem = React.memo(({ item, child, onClick, pathname }) => {
  const { t } = useTranslation();
  const childClassNames = [];
  const path =
    child.path.substr(0, 1) === "/" ? child.path : `${item.path}/${child.path}`;
  if (child.className) {
    childClassNames.push(child.className);
  }
  if (path === pathname) {
    childClassNames.push("active");
  }

  let libelle = t(child.translationKey);

  return (
    <li>
      <Link to={path} onClick={onClick} className={childClassNames.join(" ")}>
        {child.icon ? (
          <React.Fragment>
            <span className="icon" />
            <span className="label">{libelle}</span>
          </React.Fragment>
        ) : (
          libelle
        )}
      </Link>
    </li>
  );
});

const Item = React.memo(({ item, onClick }) => {
  const { t } = useTranslation();
  const libelle = t(item.translationKey);

  return (
    <Match path={item.path}>
      {props => {
        const className =
          props.match ||
          (!item.exactMatch &&
            props.location.pathname.substr(0, item.path.length) ===
              item.path) ||
          (item.includes && item.includes.includes(props.location.pathname))
            ? "active"
            : "";

        if (item.externalLink) {
          return (
            <li className={className}>
              <a
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                className={item.className}
              >
                {item.icon ? (
                  <React.Fragment>
                    <span className="icon" />
                    <span className="label">{libelle}</span>
                  </React.Fragment>
                ) : (
                  libelle
                )}
              </a>
            </li>
          );
        }

        return (
          <li className={className}>
            <Link to={item.path} onClick={onClick} className={item.className}>
              {item.icon ? (
                <React.Fragment>
                  <span className="icon" />
                  <span className="label">{libelle}</span>
                </React.Fragment>
              ) : (
                libelle
              )}
            </Link>
            {item.children && item.children.length > 0 && (
              <ul className="children">
                {item.children.map((child, index) => (
                  <ChildrenItem
                    item={item}
                    child={child}
                    onClick={onClick}
                    pathname={props.location.pathname}
                    key={index}
                  />
                ))}
              </ul>
            )}
          </li>
        );
      }}
    </Match>
  );
});

const Menu = React.memo(({ menuItems, onClick, style }) => {
  // let StyledMenu = defautStyle(style ? style : styled.nav``);
  let StyledMenu = style ? style : MenuStyled;

  if (!menuItems) return null;

  if (Array.isArray(menuItems) && menuItems.length > 0) {
    return (
      <StyledMenu className="menu">
        <ul>
          {menuItems.map((item, index) => (
            <Item key={index} item={item} onClick={onClick} />
          ))}
        </ul>
      </StyledMenu>
    );
  }

  return (
    <StyledMenu className="menu">
      {menuItems.main && menuItems.main.length > 0 && (
        <ul className="main">
          {menuItems.main.map((item, index) => (
            <Item key={index} item={item} onClick={onClick} />
          ))}
          {/*<li className={meubles.includes(path) ? 'active' : ''}><Link to='/meubles/' onClick={this.handleRemoveMenu}>Meubles</Link></li>*/}
          {/*<li className={path==='/fabrication/' ? 'active' : ''}><Link to='/fabrication/' onClick={this.handleRemoveMenu}>Sur mesure</Link></li>*/}
        </ul>
      )}

      {menuItems.second && menuItems.second.length > 0 && (
        <ul className="second">
          {menuItems.second.map((item, index) => (
            <Item key={index} item={item} onClick={onClick} />
          ))}
        </ul>
      )}
    </StyledMenu>
  );
});

export default Menu;
