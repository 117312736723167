import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { LocationProvider } from "@reach/router";
import { I18nextProvider } from "react-i18next";

import firebase from "./config/firebase";
import i18n from "../i18n";
import createContext from "./config/createContext";
import { load, logout } from "./plugins/user/actions";
const contextData = createContext();

export default ({ root, hasUser }) => {
  const Frontend = root;

  if (hasUser) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        contextData.store.dispatch(load(user));
      } else {
        contextData.store.dispatch(logout());
      }
    });
  }

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={{ mobile: contextData.isMobileScreen }}>
        <LocationProvider history={contextData.history}>
          <Provider store={contextData.store}>
            <React.Fragment>
              <Frontend contextData={contextData} />
            </React.Fragment>
          </Provider>
        </LocationProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
};
