import React from "react";
import PropTypes from "prop-types";
import { Title } from "../../../../template";
import Photos from "./Photos";
import Prix from "./Prix";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
const md = require("markdown-it")();

const Div = styled.div`
  display: flex;
  flex-direction: ${props => (props.theme.mobile ? "column-reverse" : "row")};

  .description {
    width: ${props => (props.theme.mobile ? "100%" : "300px")};
    margin-right: 1.5rem;

    > h3 {
      font-size: ${props => (props.theme.mobile ? "18px" : "28px")};
      line-height: ${props => (props.theme.mobile ? "29px" : "45px")};
    }

    .content {
      font-style: italic;
    }
  }

  ${props => {
    if (!props.theme.mobile) {
      return {
        [`@media (max-width : 900px)`]: {
          flexDirection: "column-reverse",
          [`.description`]: {
            width: "auto",
            alignSelf: "center",
            textAlign: "center"
          },
          [`.photos`]: {
            margin: "1.5rem 1.5rem 0 1.5rem"
          }
        }
      };
    } else {
      return {
        [`.description`]: {
          alignSelf: "center",
          textAlign: "center",
          margin: 0
        },
        [`.photos`]: {
          marginTop: "1.5rem"
        }
      };
    }
  }};

  dl {
    display: block;

    dt,
    dd {
      display: inline;
    }

    dt {
      margin-right: 1.5rem;
      font-weight: 100;
      white-space: nowrap;
    }

    dd {
      margin: 0;
      &:after {
        content: "";
        display: table;
      }
    }
  }

  .more-photos {
    margin-top: 1.5rem;
    margin-right: ${props => (props.theme.mobile ? 0 : "1.5rem")};
    flex: 5;
  }

  h3 {
    margin-top: 1.5rem;
  }
  h4 {
    clear: both;
  }
`;

const Stock = ({ defaultItem: data }) => {
  const { t } = useTranslation();

  let dimensions = [];
  if (data.width) {
    dimensions.push(<dt key="0_1">{t("product.width")}></dt>);
    dimensions.push(<dd key="0_2">{data.width} cm</dd>);
  }
  if (data.depth) {
    dimensions.push(<dt key="1_1">{t("product.depth")}</dt>);
    dimensions.push(<dd key="1_2">{data.depth} cm</dd>);
  }
  if (data.height) {
    dimensions.push(<dt key="2_1">{t("product.height")}</dt>);
    dimensions.push(<dd key="2_2">{data.height} cm</dd>);
  }
  if (data.diametre) {
    dimensions.push(<dt key="3_1">{t("product.diameter")}</dt>);
    dimensions.push(<dd key="3_2">{data.diametre} cm</dd>);
  }
  if (data.weight) {
    dimensions.push(<dt key="4_1">{t("product.weight")}</dt>);
    dimensions.push(<dd key="4_2">{data.weight} kg</dd>);
  }
  if (data.fragile) {
    dimensions.push(<dt key="5_1">{t("product.brittle")}</dt>);
    dimensions.push(<dd key="5_2">Produit fragile</dd>);
  }
  if (data.dimensions && data.dimensions.length > 0) {
    data.dimensions.forEach((dimension, index) => {
      if (dimension.libelle && dimension.valeur) {
        dimensions.push(<dt key={`${index + 6}_1`}>{dimension.libelle}</dt>);
        dimensions.push(<dd key={`${index + 6}_2`}>{dimension.valeur} cm</dd>);
      }
    });
  }

  let tabs = data.tabs ? data.tabs : [];
  if (dimensions) {
    tabs.unshift({
      title: "Dimensions",
      content: ``
    });
  }

  let renseignements = [];
  if (data.features && data.features.length > 0) {
    data.features.forEach((feature, index) => {
      if (feature.libelle && feature.valeur) {
        renseignements.push(<dt key={`${index}_1`}>{feature.libelle}</dt>);
        renseignements.push(<dd key={`${index}_2`}>{feature.valeur}</dd>);
      }
    });
  }

  let title = data.shortname ? data.shortname : data.name;
  let name = data.name;
  let description = data.description;
  if (
    window.navigator.language.toLocaleLowerCase().substring(0, 2) !== "fr" &&
    data.traductions &&
    data.traductions.en
  ) {
    title = data.traductions.en.shortname
      ? data.traductions.en.shortname
      : data.traductions.en.name
      ? data.traductions.en.name
      : title;
    name = data.traductions.en.name ? data.traductions.en.name : name;
    description = data.traductions.en.description
      ? data.traductions.en.description
      : description;
  }

  return (
    <React.Fragment>
      <Title textAlign="left">{title}</Title>
      <Div>
        {data.photos && data.photos.length > 1 && (
          <Photos datas={data.photos} />
        )}

        <div className="description">
          <h3>{name}</h3>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: md.render(description) }}
          />

          <Prix data={data} />

          {dimensions.length > 0 && (
            <React.Fragment>
              <h4>Dimensions</h4>
              <dl>{dimensions}</dl>
            </React.Fragment>
          )}

          {renseignements.length > 0 && (
            <React.Fragment>
              <h4>{t("informations")}</h4>
              <dl>{renseignements}</dl>
            </React.Fragment>
          )}
        </div>
      </Div>
    </React.Fragment>
  );
};

Stock.propTypes = {
  defaultItem: PropTypes.object.isRequired
};

export default Stock;
