import React, { Component } from "react";
import { connect } from "react-redux";
import { Content, Form } from "../template";
import actionObject from "../innedit_src/datas/Action";
import { USER_UPDATE_FIELDS } from "../innedit_src/plugins/user/actionTypes";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    this.props.dispatch({
      type: USER_UPDATE_FIELDS,
      data: data
    });
    return actionObject.create(data, "demande-create");
  }

  success({ email }) {
    return (
      <React.Fragment>
        <div style={{ width: "300px", margin: "0 auto" }}>
          <p style={{ textAlign: "left" }}>
            Votre demande a bien été prise en compte. Vous allez être
            directement contacter par mail sous 24-48h.
          </p>
          <p style={{ textAlign: "left" }}>En vous remerciant par avance.</p>
          <p style={{ textAlign: "center" }}>Rodrigue Verhaeghe</p>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Content
        title="Contact"
        head={{
          title:
            "Besoin d'un renseignement ? Contactez-moi - Rodrigue Verhaeghe"
        }}
      >
        <Form
          onSubmit={this.handleSubmit}
          datas={this.props.datas}
          className="main"
          textSubmit="Envoyer votre demande"
          success={this.success}
        >
          <p>
            Vous pouvez me contacter directement par téléphone au
            00.33(0)684.59.61.14 ou par mail à l'adresse suivante :
          </p>
          <p>info@rodrigueverhaeghe.com</p>
        </Form>
      </Content>
    );
  }
}

Contact.defaultProps = {
  datas: {
    contact_firstname: {
      label: "Prénom",
      type: "text"
    },
    contact_lastname: {
      label: "Nom",
      type: "text"
    },
    contact_phone: {
      label: "Téléphone",
      type: "text"
    },
    contact_email: {
      label: "Adresse e-mail",
      required: true,
      type: "email"
    },
    contact_entreprise: {
      label: "Entreprise",
      type: "text"
    },
    contact_city: {
      label: "Ville",
      type: "text"
    },
    content: {
      label: "Commentaires / Questions",
      component: "textarea",
      componentStyle: { minHeight: "180px" },
      required: true,
      style: { width: "100%" }
    }
  }
};

export default connect()(Contact);
