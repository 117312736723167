import React from "react";
import styled from "styled-components";

const H1 = styled.h1`
  text-align: ${props => (props.textAlign ? props.textAlign : "left")};
  margin: 10px 0;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "1.5rem")};

  ${props => {
    if (props.theme.mobile) {
      return {
        fontSize: "24px",
        lineHeight: "39px",
        textAlign: "center"
      };
    } else {
      return {
        [`@media (max-width : 900px)`]: {
          fontSize: "48px",
          lineHeight: "76px",
          textAlign: "center",
          marginLeft: 0
        }
      };
    }
  }};
`;

export default ({ children, ...others }) => <H1 {...others}>{children}</H1>;
