import React from "react";
import styled from "styled-components";
import DefaultHeader from "../../innedit_src/templates/Header";
import { Hamburger } from "../../template";
import Logo from "./Logo";

const styleHeader = styled.header`
  height: 100%;
  background: #b08a35;
  position: fixed;

  width: ${props => (props.theme.mobile ? "100%" : "280px")};

  ${props => {
    if (!props.theme.mobile) {
      return {
        minHeight: "100vh",
        [`@media (max-width : 900px)`]: {
          width: "210px"
        }
      };
    } else {
      return {
        padding: 0,
        height: "48px",
        background: "#b08a35",
        transition: "background 0.35s linear, height 0.35s ease-in 200ms",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 1500,
        [`&.is-home`]: {
          height: "165px",
          [`.bloc-logo`]: {
            top: "10px"
          }
        },
        [`&.is-open`]: {
          height: "100%",
          transitionDelay: "0s",
          background: "#b08a35",
          bottom: 0,
          [`.bloc-logo`]: {
            top: "-86px"
          },
          [`.menu`]: {
            display: "block",
            [`ul`]: {
              visibility: "visible",
              transitionDelay: "0s",
              [`li`]: {
                transitionDelay: "300ms, 300ms",
                opacity: 1,
                pointerEvents: "auto",
                transform: "none"
              }
            }
          }
        }
      };
    }
  }};
`;

const styleMenu = styled.nav`
  flex-direction: column;
  flex: 1;

  > ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    li {
      display: inline-block;
      margin-left: 30px;

      a {
        display: inline-block;
        color: #000;
        font-size: 18px;
        line-height: 29px;
        font-weight: 100;

        padding: 5px 10px;
        text-decoration: none;
      }

      &.active > a {
        font-weight: 400;
      }

      .children {
        font-weight: 100;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        a {
          font-size: 15px;
          line-height: 24px;

          &.active {
            font-weight: 400;
          }
        }
      }
    }
  }

  ${props => {
    if (props.theme.mobile) {
      return {
        top: "-86px",
        position: "relative",
        display: "none",
        overflow: "hidden",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        ul: {
          padding: "3rem 3rem 0 3rem",
          visibility: "hidden",
          overflow: "hidden",
          transition: "visibility 0s linear 1s",
          li: {
            display: "block",
            opacity: 0,
            pointerEvents: "none",
            transform: "scale(1.1) translateX(-24px)",
            transition:
              "opacity 0.35s ease-out, transform 0.35s ease-out, -webkit-transform 0.35s ease-out"
          }
        }
      };
    } else {
      return {
        position: "fixed",
        top: "200px"
      };
    }
  }};
`;

class Header extends DefaultHeader {
  constructor(props) {
    super(props, {
      openMenu: !props.mobile,
      menuItems: {
        main: [
          {
            exactMatch: true,
            libelle: "Accueil",
            path: "/",
            translationKey: "menu.welcome"
          },
          {
            libelle: "Inventaire",
            path: "/inventaire",
            translationKey: "menu.inventory",
            children: [
              {
                libelle: "Décoration",
                path: "decoration",
                translationKey: "menu.decorative"
              },
              {
                libelle: "Luminaire",
                path: "luminaire",
                translationKey: "menu.lighting"
              },
              {
                path: "assise",
                libelle: "Assise",
                translationKey: "menu.seat"
              },
              {
                path: "table",
                libelle: "Table",
                translationKey: "menu.table"
              },
              {
                path: "meuble",
                libelle: "Meuble",
                translationKey: "menu.furniture"
              },
              {
                path: "/vendus",
                libelle: "Objets vendus",
                translationKey: "menu.sold-items"
              }
            ]
          },
          {
            path: "/histoire",
            libelle: "A Propos",
            translationKey: "menu.about"
          },
          {
            path: "/transport",
            libelle: "Transport",
            translationKey: "menu.shipping"
          },
          {
            path: "https://www.instagram.com/rodrigue.verhaeghe/",
            externalLink: true,
            libelle: "Instagram",
            translationKey: "menu.instagram"
          },
          {
            path: "/newsletters",
            libelle: "Newsletters",
            translationKey: "menu.newsletters"
          },
          {
            path: "/contact",
            libelle: "Contact",
            translationKey: "menu.contact"
          }
        ]
      },
      styleHeader: styleHeader,
      styleMenu: styleMenu
    });
  }

  content() {
    return (
      <React.Fragment>
        <Hamburger
          handleToggleMenu={this.handleToggleMenu}
          openMenu={this.state.openMenu}
        />
        <Logo handleRemoveMenu={this.handleRemoveMenu} />
      </React.Fragment>
    );
  }
}

export default Header;
