import React, { Component } from "react";
import { Link } from "@reach/router";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import { Modal, Produit } from "../../../template";
import chevron from "../../assets/images/chevron.svg";
import ProduitObject from "../../datas/Produit";

const stylePagination = styled.div`
  display: flex;
  margin: 1.5rem;

  ul {
    flex: 1;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0.375rem;

      .page {
        border-radius: 15px;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        background: #ccc;
        cursor: pointer;

        &.prev {
          .text {
            display: none;
          }
          .icon {
            mask: url(${chevron});
            width: 14px;
            height: 14px;
            margin: 8px 0 8px 7px;
            background: #333;
            display: block;
            transform: rotate(180deg);
          }
        }

        &.next {
          .text {
            display: none;
          }
          .icon {
            mask: url(${chevron});
            width: 14px;
            height: 14px;
            margin: 8px 0 8px 9px;
            background: #333;
            display: block;
          }
        }

        &.selected {
          background: #bd0b02;
          color: white;
        }
      }
    }
  }

  .infos {
    height: 30px;
    line-height: 30px;
    align-self: center;
  }
`;

class Produits extends Component {
  stylePagination = stylePagination;

  state = {
    index: null,
    mustBeLoaded: false,
    q: null,
    facetFilters: null,
    produits: null,
    nbParPage: null,
    nbHits: null,
    nbPages: null,
    page: 0
  };

  constructor(props) {
    super(props);

    this.searchProduits = this.searchProduits.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.showEmpty = this.showEmpty.bind(this);
    this.showList = this.showList.bind(this);
  }

  componentDidMount() {
    if (this.state.mustBeLoaded) {
      this.searchProduits(this.state.facetFilters);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoaded) {
      this.searchProduits(this.state.facetFilters);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      (props.facetFilters &&
        (!state.facetFilters ||
          !isEqual(state.facetFilters, props.facetFilters))) ||
      (props.nbParPage &&
        (!state.nbParPage || state.nbParPage !== props.nbParPage)) ||
      props.q !== state.q
    ) {
      state.mustBeLoaded = true;
      state.index = null;
      state.facetFilters = props.facetFilters;
      state.nbParPage = props.nbParPage;
      state.page = 0;
      state.q = props.q;
    }

    return state;
  }

  searchProduits() {
    this.setState({
      mustBeLoaded: false
    });
    ProduitObject.search({
      q: this.state.q,
      database: "algolia",
      facetFilters: this.state.facetFilters,
      nbParPage: this.state.nbParPage,
      pageIndex: this.state.page
    }).then(({ hits, nbHits, nbPages, page }) => {
      this.setState({
        produits: hits,
        nbHits,
        nbPages,
        page
      });
    });
  }

  componentWillUnmount() {
    document.body.style.position = null;
    document.body.style.top = null;
    document.body.style.width = null;

    document.documentElement.classList.remove("is-clipped");
  }

  handleOnClick(e) {
    if (this.props.showModal) {
      e.preventDefault();
      // TODO j'ai un probleme avec cette fonctionnalité
      //navigate(this.props.location.origin + e.currentTarget.pathname);
      // const top = document.documentElement.scrollTop;
      document.documentElement.classList.toggle("is-clipped");
      // document.body.style.position = "fixed";
      // document.body.style.top = "-" + top + "px";
      // document.body.style.width = "100%";
      this.setState({
        index: e.currentTarget.getAttribute("data-index")
      });

      return false;
    }
  }

  handlePrevious(e) {
    console.info("handlePrevious");
    e.preventDefault();

    this.setState(
      oldState => {
        const index = Math.max(parseInt(oldState.index, 10) - 1, 0);
        return {
          index
        };
      },
      () => {
        // navigate(
        //   this.props.location.origin +
        //     "/" +
        //     this.state.produits[this.state.index].pathname,
        //   {
        //     replace: true
        //   }
        // );
      }
    );
  }

  handleNext(e) {
    console.info("handleNext");
    e.preventDefault();

    this.setState(
      oldState => {
        const index = Math.min(
          parseInt(oldState.index) + 1,
          this.state.produits.length - 1
        );
        return {
          index
        };
      },
      () => {
        // navigate(
        //   this.props.location.origin +
        //     "/" +
        //     this.state.produits[this.state.index].pathname,
        //   {
        //     replace: true
        //   }
        // );
      }
    );
  }

  handleCloseModal(e) {
    e.preventDefault();
    this.setState({
      index: null
    });
    document.documentElement.classList.remove("is-clipped");
    // document.body.style.position = null;
    // document.body.style.top = null;
    // document.body.style.width = null;

    // window.history.back();
  }

  handleChangePage(e) {
    const page = parseInt(e.currentTarget.getAttribute("data-page"), 10);
    this.setState({ page, mustBeLoaded: true }, () => {
      this.searchProduits();
      window.scrollTo(0, 0);
    });
  }

  showEmpty() {
    if (this.state.produits && this.state.produits.length === 0) {
      return <p>Aucun produit</p>;
    }
  }

  showPagination() {
    if (
      !this.state.produits ||
      this.state.produits.length === 0 ||
      this.state.nbPages === 1
    ) {
      return null;
    }

    const pages = [];
    for (
      let i = Math.max(1, this.state.page - 2);
      i < Math.min(this.state.nbPages - 1, this.state.page + 3);
      i++
    ) {
      pages.push(i);
    }

    const Div = this.stylePagination;

    return (
      <Div>
        <ul>
          {this.state.page > 1 && (
            <li>
              <button
                data-page={parseInt(this.state.page) - 1}
                className={`page prev`}
                onClick={this.handleChangePage}
              >
                <span className="icon" />
                <span className="text">Précedente</span>
              </button>
            </li>
          )}
          <li>
            <button
              data-page={0}
              className={`page ${this.state.page === 0 ? "selected" : ""}`}
              onClick={this.handleChangePage}
            >
              1
            </button>
          </li>
          {this.state.page > 3 && (
            <li>
              {this.state.page === 4 ? (
                <button
                  className={`page`}
                  data-page={1}
                  onClick={this.handleChangePage}
                >
                  2
                </button>
              ) : (
                <button className={`page`}>...</button>
              )}
            </li>
          )}
          {pages.map((page, index) => (
            <li key={index}>
              <button
                data-page={page}
                className={`page ${this.state.page === page ? "selected" : ""}`}
                onClick={this.handleChangePage}
              >
                {parseInt(page, 10) + 1}
              </button>
            </li>
          ))}
          {this.state.page < this.state.nbPages - 4 && (
            <li>
              {this.state.page === this.state.nbPages - 5 ? (
                <button
                  className={`page`}
                  data-page={parseInt(this.state.nbPages, 10) - 1}
                  onClick={this.handleChangePage}
                >
                  {this.state.nbPages - 1}
                </button>
              ) : (
                <button className={`page`}>...</button>
              )}
            </li>
          )}
          <li>
            <button
              data-page={parseInt(this.state.nbPages, 10) - 1}
              className={`page ${
                this.state.page === this.state.nbPages - 1 ? "selected" : ""
              }`}
              onClick={this.handleChangePage}
            >
              {parseInt(this.state.nbPages, 10)}
            </button>
          </li>
          {this.state.page < this.state.nbPages - 2 && (
            <li>
              <button
                data-page={parseInt(this.state.page) + 1}
                className={`page next`}
                onClick={this.handleChangePage}
              >
                <span className="icon" />
                <span className="text">Suivante</span>
              </button>
            </li>
          )}
        </ul>
      </Div>
    );
  }

  showList() {
    if (!this.state.produits || this.state.produits.length === 0) {
      return null;
    }

    return (
      <ul className="produit-list">
        {this.state.produits.map((produit, index) => (
          <li key={index}>
            <Link
              data-id={produit.id}
              data-index={index}
              to={`/produit/${produit.pathname}`}
              onClick={this.handleOnClick}
            >
              {produit.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.index !== null && (
          <Modal
            handleCloseModal={this.handleCloseModal}
            handlePrevious={this.state.index > 0 ? this.handlePrevious : null}
            handleNext={
              this.state.produits &&
              this.state.produits.length > 1 &&
              this.state.index < this.state.produits.length - 1
                ? this.handleNext
                : null
            }
          >
            <Produit document={this.state.produits[this.state.index]} modal />
          </Modal>
        )}

        {this.showEmpty()}
        {!this.props.hidePagination &&
          !this.props.hidePaginationTop &&
          this.showPagination()}
        {this.showList()}
        {!this.props.hidePagination &&
          !this.props.hidePaginationBottom &&
          this.showPagination()}
      </React.Fragment>
    );
  }
}

Produits.defaultProps = {
  showModal: false,
  nbByRow: 4,
  hitsPerPage: 40
};

export default Produits;
