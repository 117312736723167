import React, { Component } from "react";
import Url from "../../datas/Url";
import { Content, Produit, Article, Page, Page404 } from "../../../template";
import { JSONLD, Product } from "react-structured-data";

class Dispatcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uri: undefined,
      mustBeUpdated: false,
      document: undefined,
      error: false,
      message: undefined,
      type: undefined
    };

    this.loadDocument = this.loadDocument.bind(this);
  }

  componentDidMount() {
    if (this.state.mustBeUpdated) {
      this.loadDocument();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeUpdated) {
      this.loadDocument();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.uri || state.uri !== props.uri) {
      state.mustBeUpdated = true;
      state.uri = props.uri;
      state.document = undefined;
    } else {
      state.mustBeUpdated = false;
    }

    return state;
  }

  loadDocument() {
    const _this = this;
    this.setState({
      mustBeUpdated: false
    });

    Url.findByPathname(this.state.uri.substr(1))
      .then(url => {
        if (url.get("isRedirect")) {
          // C'est une redirection
          return _this.props.navigate(url.get("redirectPathname"));
        }

        return url
          .get("docRef")
          .get()
          .then(document => {
            _this.setState({
              document: document,
              type: url.get("type"),
              error: false,
              message: undefined
            });
          });
      })
      .catch(error => {
        console.error(error.message);
        _this.setState({
          error: true,
          message: error.message
        });
      });
  }

  render() {
    //TODO améliorer cette partie - Voir si c'est necessaire d'afficher un loader
    if (this.state.mustBeUpdated) {
      return null;
    }

    if (this.state.error) {
      switch (this.state.error) {
        case 404:
        default:
          return <Page404 />;
      }
    }

    if (!this.state.document) {
      return null;
    }

    let title;
    switch (this.state.type) {
      case "produit":
        title = this.state.document.get("title")
          ? this.state.document.get("title")
          : this.state.document.get("name");

        if (this.state.document.get("isOnlineStore")) {
          const photos = this.state.document.get("photos").map(photo => {
            return `${
              process.env.REACT_APP_CDN_DOMAIN_NAME
            }/${photo}?w=1080&h=1080`;
          });

          const brand = {
            "@type": "Thing",
            name: process.env.REACT_APP_BRAND_NAME
          };

          let itemCondition = "https://schema.org/NewCondition";
          switch (this.state.document.get("onSale")) {
            case "used":
              itemCondition = "https://schema.org/UsedCondition";
              break;

            case "refurbished":
              itemCondition = "https://schema.org/RefurbishedCondition";
              break;

            default:
              itemCondition = "https://schema.org/NewCondition";
          }

          const availability = this.state.document.get("onSale")
            ? "https://schema.org/InStock"
            : "http://schema.org/SoldOut";

          const offers = {
            itemCondition,
            availability,
            "@type": "Offer",
            url: `${
              process.env.REACT_APP_DOMAIN_NAME
            }/${this.state.document.get("pathname")}`,
            priceCurrency: "EUR",
            price: this.state.document.get("price"),
            seller: {
              "@type": "Organization",
              name: process.env.REACT_APP_SELLER_NAME
            }
          };

          return (
            <React.Fragment>
              <JSONLD>
                <Product
                  name={this.state.document.get("name")}
                  image={photos}
                  description={this.state.document.get("description")}
                  sku={this.state.document.get("sku")}
                  brand={brand}
                  offers={offers}
                />
              </JSONLD>
              <Produit
                document={this.state.document}
                navigate={this.props.navigate}
              />
            </React.Fragment>
          );
        }
        return <Content title={title}>Ce produit n'est pas visible</Content>;

      case "post":
        title = this.state.document.get("title");

        if (!this.state.document.get("isVisible")) {
          return (
            <Content title={title}>Cette article n'est pas visible</Content>
          );
        }
        return (
          <Article
            item={this.state.document.data()}
            navigate={this.props.navigate}
          />
        );

      case "redirect":
        return <Content>Traitement à écrire</Content>;

      case "page":
      default:
        if (!this.state.document.get("isVisible")) {
          title = this.state.document.get("title")
            ? this.state.document.get("title")
            : this.state.document.get("name");

          return <Content title={title}>Cette page n'est pas visible</Content>;
        }
        return <Page document={this.state.document} head={this.props.head} />;
    }
  }
}

export default Dispatcher;
