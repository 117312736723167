import React from "react";
import styled from "styled-components";
import photo from "../assets/photos/accueil_4.jpg";

const Div = styled.div`
  height: ${props => (props.theme.mobile ? "calc(100vh - 175px)" : "100vh")};
  background-image: url(${photo});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Index = () => <Div />;

export default Index;
