import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
import logo from "../../assets/images/logo.svg";

const Div = styled.div`
  position: fixed;
  width: 280px;
  align-items: center;
  justify-content: center;
  top: 10px;

  a {
    outline: none;
    display: inline-block;
    width: 100%;

    .logo {
      display: inline-block;
      margin-top: 10px;
      margin-bottom: 10px;
      background-image: url(${logo});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 130px;
      width: 100%;
      color: transparent;
    }
  }

  ${props => {
    if (props.theme.mobile) {
      return {
        position: "relative",
        width: "100%",
        top: "-86px",
        a: {
          [`.logo`]: {
            height: "115px"
          }
        }
      };
    } else {
      return {
        [`@media (max-width : 900px)`]: {
          width: "210px",
          [`a .logo`]: {
            height: "110px"
          }
        }
      };
    }
  }};
`;

const Logo = ({ handleRemoveMenu }) => (
  <Div className="bloc-logo">
    <Link to="/" onClick={handleRemoveMenu}>
      <span className="logo">Rodrigue Verhaeghe</span>
    </Link>
  </Div>
);

export default Logo;
