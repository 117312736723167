import { createStore, applyMiddleware } from "redux";
import promiseMiddleware from "redux-promise";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import reducers from "./reducers/index";

const loggerMiddleware = createLogger();

export default preloadedState => {
  let store;

  if (process.env.NODE_ENV === "development") {
    store = createStore(
      reducers,
      preloadedState,
      applyMiddleware(promiseMiddleware, thunkMiddleware, loggerMiddleware)
    );
  } else {
    store = createStore(
      reducers,
      preloadedState,
      applyMiddleware(promiseMiddleware, thunkMiddleware)
    );
  }

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers", () => {
      const nextRootReducer = require("./reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};
