import firebase from "../config/firebase";
import { getFirestore } from "../config/functions";

class User {
  static login(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  static logout() {
    return firebase.auth().signOut();
  }

  static get() {
    return firebase.auth();
  }

  static update(user) {
    const currentUser = firebase.auth().currentUser;
    return currentUser.updateProfile({
      displayName: user.displayName,
      phoneNumber: user.phoneNumber
    });
  }

  /**
   * Recherche d'un produit par son identifiant
   *
   * @param {string} email
   * @returns {Promise<firebase.firestore.DocumentSnapshot>}
   */
  static findByEmail(email) {
    // TODO ajouter un test pour vérifier que c'est bien une adresse email
    return getFirestore()
      .collection("contacts")
      .where("email", "==", email.toLowerCase().trim())
      .where("deleted", "==", false)
      .get()
      .then(querySnapshot => {
        if (querySnapshot && !querySnapshot.empty) {
          return querySnapshot.docs[0];
        }
        throw new Error("Erreur lors de la recherche de l'utilisateur");
      });
  }
}

export default User;
