import React from "react";
import { Router } from "@reach/router";
import Urls from "./innedit_src/plugins/urls";
import Demande from "./innedit_src/containers/Demande/index";

import { Layout } from "./template";

import Index from "./pages/index";
import Catalogue from "./pages/catalogue";
import Contact from "./pages/contact";
import Newsletter from "./pages/newsletter";
// import PopupNewsletter from "./templates/PopupNewsletter";

import "./styles/normalize.css";
import "./styles/index.css";

export default ({ contextData, ...others }) => {
  const nbByRowMobile = 2;
  const nbByRowDesktop = 3;
  const nbByRow = contextData.isMobileScreen ? nbByRowMobile : nbByRowDesktop;
  const hitsPerPage = 21;

  const catalogue = {
    inventaire: {
      title: "Inventaire",
      traductions: {
        en: {
          title: "Inventory"
        }
      },
      facetFilters: [
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["onSale:true"],
        ["isOnlineStore:true"],
        ["isDraft:false"]
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem"
    },
    decoration: {
      title: "Décoration",
      traductions: {
        en: {
          title: "Decorative"
        }
      },
      facetFilters: [
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["onSale:true"],
        ["isOnlineStore:true"],
        ["category:aIlz569FyHez3nTyzDJo"],
        ["isDraft:false"]
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem"
    },
    luminaire: {
      title: "Luminaire",
      traductions: {
        en: {
          title: "Lighting"
        }
      },
      facetFilters: [
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["onSale:true"],
        ["isOnlineStore:true"],
        ["category:F5Bk3ScCTHFEvYPIqP3t"],
        ["isDraft:false"]
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem"
    },
    assise: {
      title: "Assise",
      traductions: {
        en: {
          title: "Seat"
        }
      },
      facetFilters: [
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["onSale:true"],
        ["isOnlineStore:true"],
        ["category:4UESzH7EIdhC0j2vgSOf"],
        ["isDraft:false"]
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem"
    },
    table: {
      title: "Table",
      traductions: {
        en: {
          title: "Table"
        }
      },
      facetFilters: [
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["onSale:true"],
        ["isOnlineStore:true"],
        ["category:0f3WN9nCvtgmeBgimU9W"],
        ["isDraft:false"]
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem"
    },
    meuble: {
      title: "Meuble",
      traductions: {
        en: {
          title: "Furniture"
        }
      },
      facetFilters: [
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["onSale:true"],
        ["isOnlineStore:true"],
        ["category:FACMOsEMaPpUxZuduzea"],
        ["isDraft:false"]
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem"
    },
    vendus: {
      title: "Objets vendus",
      traductions: {
        en: {
          title: "Sold items"
        }
      },
      facetFilters: [
        [`boutique:${process.env.REACT_APP_ID_BOUTIQUE}`],
        ["inStock:false"],
        ["hasInventory:true"],
        ["isOnlineStore:true"],
        ["isDraft:false"]
      ],
      orderField: "datetime",
      orderDirection: "desc",
      nbByRow: nbByRow,
      showModal: false,
      showPrice: false,
      nbParPage: hitsPerPage,
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem"
    }
  };

  return (
    <Layout
      mobile={contextData.isMobileScreen}
      pathname={contextData.history.location.pathname.substr(1)}
    >
      <Router>
        <Index path="/" nbByRow={nbByRow} />

        <Catalogue path="inventaire" {...catalogue.inventaire} />
        <Catalogue path="inventaire/decoration" {...catalogue.decoration} />
        <Catalogue path="inventaire/luminaire" {...catalogue.luminaire} />
        <Catalogue path="inventaire/assise" {...catalogue.assise} />
        <Catalogue path="inventaire/table" {...catalogue.table} />
        <Catalogue path="inventaire/meuble" {...catalogue.meuble} />
        <Catalogue path="vendus" {...catalogue.vendus} />

        <Contact path="contact">
          <Contact path={":id"} />
        </Contact>

        <Newsletter path="newsletters" />

        <Demande path="demande/:demandeId" />
        <Urls default />
      </Router>
    </Layout>
  );
};
