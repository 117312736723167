// import "core-js/es6/map";
// import "core-js/es6/set";
import "raf/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Innedit from "./innedit_src/";
import Frontend from "./frontend";
import "moment/locale/fr";

ReactDOM.render(<Innedit root={Frontend}/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();