import styled from "styled-components";
import ButtonDefault from "../innedit_src/templates/Button";

const styledButton = styled.button`
  appearance: none !important;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 100;
  margin-top: 0.75rem;
  margin-right: ${props => (props.theme.mobile ? 0 : "0.375rem")};
  margin-bottom: 0.75rem;
  margin-left: ${props => (props.theme.mobile ? 0 : "0.375rem")};
  width: ${props => (props.theme.mobile ? "100%" : "auto")};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background: ${props => (props.primary ? "#000" : "#ccc")};
  border-color: ${props => (props.primary ? "#000" : "#ccc")};
  color: ${props => (props.primary ? "#fff !important" : "#666")};
  padding: 0.75rem 1.5rem;
  border-radius: 0;
  outline: 0;
  vertical-align: middle;
  text-transform: uppercase;
  line-height: 1rem;
  font-family: "Advent Pro", sans-serif;
`;

class Button extends ButtonDefault {
  constructor(props) {
    super(props, {
      style: styledButton
    });
  }
}
export default Button;
