import React from "react";
import styled from "styled-components";
import { Header, Footer } from "../template";

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;
  background: ${props => (props.theme.mobile ? "#fff" : "#b08a35")};

  ${props => {
    if (props.theme.mobile) {
      return {
        [`.body`]: {
          top: "48px",
          padding: "0 1.5rem"
        },
        [`.is-home + .body`]: {
          top: "175px",
          padding: 0
        }
      };
    }
  }};
`;

const Body = styled.div`
  flex: 1;
  background: white;
  padding-left: 10px;
  box-sizing: border-box;

  > .page {
    min-height: 100vh;
  }

  ${props => {
    if (props.theme.mobile) {
      return {
        position: "relative"
      };
    } else {
      return {
        marginLeft: "280px",
        [`@media (max-width : 900px)`]: {
          marginLeft: "210px"
        }
      };
    }
  }};
`;

const LayoutCmp = props => {
  const { loading, mobile, children } = props;
  return (
    <Layout>
      <Header mobile={mobile} />
      <Body className="body">
        <div className="page">
          {loading ? <div>Chargement en cours</div> : children}
        </div>
        <Footer />
      </Body>
    </Layout>
  );
};

export default LayoutCmp;
