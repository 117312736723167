import React from "react";
import styled from "styled-components";
import { Button } from "../../../../../template";

const Div = styled.div`
  border-top: 5px dotted #f6f6f6;
  margin: 6rem -1.5rem 1.5rem -1.5rem;
  margin: 6rem 0 1.5rem 0;
  padding-top: 3rem;

  text-align: center;

  .pouce {
    &:before,
    &:after {
      content: "\\01F447";
      font-size: 18px;
      margin: 0 0.75rem;
      display: inline-block;
    }
  }
`;


export default () => (
  <Div>
    <h2>Vous souhaitez ce modèle à vos dimensions ?</h2>
    <p className="pouce">
      Il vous est possible de demander la fabrication sur mesure de ce produit.
    </p>
    <Button to="/contact" primary="true">
      Demander un devis
    </Button>
  </Div>
);
