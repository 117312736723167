import React from "react";
import Helmet from "react-helmet";
import Produits from "../plugins/catalogue/Produits/index";
import styled from "styled-components";
import { Title } from "../template";

const Div = styled.div`
  display: flex;
  flex-direction: column;

  .filter {
    width: ${props => (props.theme.mobile ? "100%" : "200px")};
    padding-right: ${props => (props.theme.mobile ? 0 : "3rem")};

    .navigation {
      width: ${props => (props.theme.mobile ? "100%" : "200px")};
      margin-bottom: ${props => (props.theme.mobile ? "1.5rem" : 0)};

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: ${props => (props.theme.mobile ? "left" : "right")};

        a {
          color: #222;
          text-decoration: none;
          text-transform: capitalize;

          &.active {
            font-weight: 700;
          }
        }
      }
    }
  }

  .produits {
    display: flex;
    flex-direction: column;
    margin: 0 0.75rem;
  }
`;

const Navigation = ({ pathname }) => {
  return null;
  // return (
  //   <div className="navigation">
  //     <ul>
  //       <li style={{ marginBottom: "1.5rem" }}>
  //         <Link
  //           to="/stock"
  //           className={pathname === "/stock" ? "active" : ""}
  //           style={{ textTransform: "inherit" }}
  //         >
  //           Tous les produits
  //         </Link>
  //       </li>
  //       <li>
  //         <Link
  //           to="/stock/meubles"
  //           className={pathname === "/stock/meubles" ? "active" : ""}
  //         >
  //           Meubles
  //         </Link>
  //       </li>
  //       <li>
  //         <Link
  //           to="/stock/consoles"
  //           className={pathname === "/stock/consoles" ? "active" : ""}
  //         >
  //           Consoles
  //         </Link>
  //       </li>
  //       <li>
  //         <Link
  //           to="/stock/sieges"
  //           className={pathname === "/stock/sieges" ? "active" : ""}
  //         >
  //           Sièges
  //         </Link>
  //       </li>
  //       <li>
  //         <Link
  //           to="/stock/jardin"
  //           className={pathname === "/stock/jardin" ? "active" : ""}
  //         >
  //           Jardin
  //         </Link>
  //       </li>
  //       <li>
  //         <Link
  //           to="/stock/tables"
  //           className={pathname === "/stock/tables" ? "active" : ""}
  //         >
  //           Tables
  //         </Link>
  //       </li>
  //
  //       <li>
  //         <Link
  //           to="/stock/luminaires"
  //           className={pathname === "/stock/luminaires" ? "active" : ""}
  //         >
  //           Luminaires
  //         </Link>
  //       </li>
  //       <li>
  //         <Link
  //           to="/stock/miroirs"
  //           className={pathname === "/stock/miroirs" ? "active" : ""}
  //         >
  //           Miroirs
  //         </Link>
  //       </li>
  //       <li>
  //         <Link
  //           to="/stock/objets"
  //           className={pathname === "/stock/objets" ? "active" : ""}
  //         >
  //           Objets
  //         </Link>
  //       </li>
  //       <li>
  //         <Link
  //           to="/stock/tableaux"
  //           className={pathname === "/stock/tableaux" ? "active" : ""}
  //         >
  //           Tableaux
  //         </Link>
  //       </li>
  //       <li>
  //         <Link
  //           to="/stock/elements-architecturaux"
  //           className={
  //             pathname === "/stock/elements-architecturaux" ? "active" : ""
  //           }
  //         >
  //           éléments archicturaux
  //         </Link>
  //       </li>
  //       <li className="vendus" style={{ marginTop: "1.5rem" }}>
  //         <Link to="/vendus" className={pathname === "/vendus" ? "active" : ""}>
  //           Vendus
  //         </Link>
  //       </li>
  //     </ul>
  //   </div>
  // );
};

export default ({ title, location: { pathname }, traductions, ...others }) => {
  if (
    window.navigator.language.toLocaleLowerCase().substring(0, 2) !== "fr" &&
    traductions &&
    traductions.en
  ) {
    title = traductions.en.title;
  }
  return (
    <Div>
      <Helmet title={title} />
      <Title textAlign="left" marginLeft={"1.5rem"}>
        {title}
      </Title>
      <div className="filter">
        <Navigation pathname={pathname} />
      </div>
      <div className="produits">
        <Produits hidePaginationTop={false} {...others} />
      </div>
    </Div>
  );
};
